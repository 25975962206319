import './App.css';

function App() {
  return (
    <div className="App">
        <div className={"ml-10 mt-10"}>
            <h3>michael sachen</h3>
            <div className={"mt-16"}>
                {/*<h3><a href={"./test_resume.pdf"} target="_blank" rel="noopener noreferrer">cv,</a></h3>*/}
                {/*<h3><a href={"./test_portfolio.pdf"} target="_blank" rel="noopener noreferrer">portfolio,</a></h3>*/}
                <h3><a href="mailto:michaelpsachen@gmail.com">email,</a></h3>
                <h3><a href={"https://www.instagram.com/michael.sachen/"}>instagram</a></h3>
            </div>
        </div>
    </div>
  );
}

export default App;
